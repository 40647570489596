<template>
  <div>
    <relato-tipo
      v-for="(tipo, i) in tipos"
      :key="tipo.tipo"
      :tipo="tipo"
      :grupos="grupos"
      :itens="itens"
      :showInput="i === 0"
      v-on:novoGrupo="novoGrupo"
      v-on:novoItem="novoItem"
      v-on:excluirGrupo="excluirGrupo"
      v-on:excluirItem="excluirItem"
      v-on:excluirTipo="excluirTipo"
      v-on:editarTipo="editarTipo"
      v-on:editarGrupo="editarGrupo"
    />
  </div>

</template>

<script>
import relatoTipo from './relatosTipo.vue';

export default {
  components: { relatoTipo },
  props: {
    tipos: {
      type: Array,
      required: true,
    },
    itens: {
      type: Array,
      required: true,
    },
    grupos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tipo: { nome: '' },
      grupo: {
        nome: '',
      },
      item: {
        nome: '',
        grupo: null,
      },
    };
  },
  methods: {
    returnGrupos(id) {
      let resultado = [];
      if (id) {
        resultado = this.grupos.filter(g => (g.tipo === id));
      }
      return resultado;
    },
    novoGrupo(event) {
      this.$emit('novoGrupo', event);
    },
    novoItem(event) {
      this.$emit('novoItem', event);
    },
    excluirGrupo(grupo) {
      this.$emit('excluirGrupo', grupo);
    },
    excluirItem(item) {
      this.$emit('excluirItem', item);
    },
    excluirTipo(tipo) {
      this.$emit('excluirTipo', tipo);
    },
    editarTipo(tipo) {
      this.$emit('editarTipo', tipo);
    },
    editarGrupo(grupo) {
      this.$emit('editarGrupo', grupo);
    },
  },
};
</script>

<style>
.visualizations {
  border: 1px solid #ebeef5;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
.visualizations__header {
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding-left: 15px;
  border-bottom: 5px solid #4eb177;
  box-sizing: border-box;
  font-weight: bold;
}
</style>
